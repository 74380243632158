a.referenced {
	text-decoration: none;
	border-bottom: 1px dashed currentColor;
}

.erred {
	&, * {
		color: red !important;
	}
}

#thread-container, .index-thread, article {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

article {
	word-break: break-word;
	word-wrap: break-word;
	max-width: 95vw;
	ul {
		word-wrap: normal;
    	word-break: normal;
	}
	article {
		display: table;
		.post-container {
			display: flex;
			flex-direction: column;
		}
	}
}

.post-container {
	width: 100%;
	overflow: hidden; // Prevents vertical zalgo
}

.backlinks > em > a {
	font-size: smaller;
}

blockquote {
	min-width: 25em;
	b {
		color: inherit;
	}
}

audio {
	width: 300px;
	height: 3em;
	&::-webkit-media-controls-panel {
		background: none;
	}
}

h3 {
	margin: 0;
}

code.code-tag {
	margin: 0;
	white-space: pre-wrap;
}

.embed {
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 4px;
	display: inline-block;
	font-style: normal;
	margin: 0.1em;
	padding: 2px;
	color: black;
	&.expanded {
		max-width: 480px;
	}
	& > iframe {
		display: block;
		margin: auto;
		border-style: none;
	}
}

.hash-link {
	display: none;
}

em {
	font-style: normal;
}

figcaption {
	margin: 2px 0;
}

.image-search {
	font-weight: bold;
	display: none;
}

figure {
	float: left;
	margin: 3px 20px 0 0;
	padding: 0.3em;
	a {
		display: block;
		line-height: 0px;
	}
	img {
		border: 0;
	}
}

.fit-to-width {
	max-width: 100%;
}
.fit-to-screen {
	max-width: 100%;
	max-height: 100vh;
}

header {
	margin-left: -14px;
	padding-left: 14px;
	nav {
		display: initial;
	}
	code {
		font-family: inherit;
		font-weight: normal;
	}
	&.temporary > *:not(.name) {
		display: none;
	}
}

.svg-link {
	// Widen click area
	width: 1em;
	height: 1em;

	svg {
		width: 0.7em;
		height: 0.7em;
	}

	header & {
		position: relative;
		margin-left: 0.5em;
		float: right;
	}
}

.control .popup-menu {
	position: absolute;
	right: 0;
}

.omit {
	line-height: 200%;
}

.upload-container label {
	padding: 0;
}

#thread-form-container.expanded {
	padding: 0.7em;
	& > span.act {
		display: none;
	}
	& > form {
		display: block;
	}
}

#new-thread-form, #new-reply-form {
	input[type=text], textarea {
		width: 20em;
	}
}

#new-thread-form input[name=sage] {
	&, & + br {
		display: none;
	}
}

#text-input {
	color: inherit;
	font-size: inherit;
	border: 0;
	padding: 0;
	background-color: transparent;
	outline: none;
	width: 100%;
	max-height: 85vh;
	max-width: 100%;
	resize: none;
	overflow: hidden;
	overflow-wrap: break-word;
	word-break: break-word;
	word-wrap: break-word;
}

@media screen and (min-height: 200px) {
	.reply-form:not(.preview) {
		position: fixed;
		z-index: 15;
		bottom: 0;
		left: 0;
		margin: 1em;
		.post-container {
			max-height: 80vh;
			max-width: 80vw;
			display: flex;
			flex-wrap: nowrap;
		}
		figure ~ blockquote {
			margin-left: 0px;
		}
	}
}

#post-controls {
	padding: 0.3em 0 0.3em 0;
}

// Make OPs stand out, if no custom background set
#threads:not(.custom-BG) section > article.op {
	margin-left: 0;
	padding-left: 0;
	border: none;
	background: none;
}

#threads  {
	hr {
		width: 100%;
	}
	section:last-child hr {
		display: none;
	}
}

#catalog {
	margin: 0 0.85em;
	article {
		width: 160px;
		max-height: 300px;
		display: inline-block;
		text-align: center;
		overflow: hidden;
		vertical-align: top;
		padding: 0.6em;
		margin: 0.15em;
		&.deleted .deleted-toggle:checked ~ .thread-links {
			clear: none;
			display: inline-block;
		}
	}
	blockquote {
		&:empty {
			display: none;
		}
		min-width: initial;
		margin: 0;
		padding: 0;
	}
	figure {
		float: none;
		margin: 0;
		img {
			margin: 0;
		}
	}
}

// Prevent double <hr> at bottom
#index-thread-container > section:last-of-type hr {
	display: none;
}

.thread-links {
	padding: 0.3em;
}

.omit {
	white-space: nowrap;
}

header > svg {
	width: 1em;
	height: 1em;
}

a.strikethrough {
	text-decoration: line-through !important;
}

.record-button.svg-link svg {
	width: 1.2em;
	height: 1.2em;
	padding-left: 0.2em;
	padding-right: 0.2em;
}
