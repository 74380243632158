.overlay-container {
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	position: fixed;
	z-index: 300;
	pointer-events: none;
}

.overlay {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: ~"calc(100% - 1.5em)";
	pointer-events: none;
	align-items: flex-start;
}

#loading-image {
	position: fixed;
	z-index: 310;
	right: 0;
	bottom: 0;
	pointer-events: none;
}

.top-overlay {
	bottom: 0;
	right: 0;
	position: fixed;
	z-index: 320;
}

#hover-overlay {
	& > video, & > img {
		max-height: 100%;
		max-width: 100%;
		margin: auto auto;
		display: block;
		object-fit: contain;
	}
}

.popup-menu {
	padding: 5px;
	display: table;
	position: relative;
	margin-top: 0.1em;
	z-index: 15;
	white-space: nowrap;
	li {
		list-style: none;
		margin: 0;
		padding: 3px 3px 3px 5px;
	}
}

#banner {
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	height: 1.3em;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	* {
		white-space: nowrap;
	}
}

.banner-float.svg-link  {
	font-weight: bold;
	padding: 0 0.15em 0 0.15em;
	svg {
		width: 1em;
		height: 1em;
	}
}

#banner-center {
    margin-left: auto;
    margin-right: auto;
	overflow: hidden;
	text-overflow: ellipsis;
}

#left-panel, #left-spacer {
	left: 0;
	margin-left: 0;
}

#right-panel, #right-spacer {
	right: 0;
	margin-right: 0;
}

#board-navigation {
	float: left;
	a {
		font-weight: bold;
	}
}

.tab-butts {
	display: flex;
	a {
		display: inline-block;
		padding: 7px;
	}
	& + hr {
		margin: 0;
	}
}

.tab-cont{
	padding-left: 5px;
	& > div {
		display: none;
		padding-top: 5px;
		width: 100%;
		label {
			padding-left: 0.2em;
		}
		&.tab-sel {
			display: block;
		}
	}
}

#hidden {
	float: right;
}

.modal.notification {
    text-align: center;
    padding: 0.6em;
    margin-left: auto;
    margin-right: auto;
}

.modal {
	text-align: left;
	max-height: 90vh;
	max-width: 90vw;
	overflow: auto;
	margin: 0.5em;
	padding: 1em;
	white-space: nowrap;
	input[type=number] {
		width: 5em;
	}
	input[type=text], input[type=password] {
		width: 10em;
	}
	#modal-overlay > &:not(.show) {
		display: none;
		margin-left: auto;
	}
}

input[type=number], input[type=text], input[type=password], textarea {
	.full-width &, &.full-width {
		width: ~"calc(100% - 1px)";
		box-sizing: border-box;
	}
}

.modal, form {
	&.wide-fields {
		input[type=text], textarea {
			width: 20em;
			&.map-field {
				width: 9.3em;
			}
		}
	}
}

.modal, #banner {
	pointer-events: all;
}

.modal, #banner, .reply-form, .popup-menu, .preview {
	box-shadow: 3px 3px 3px 0 rgba(0,0,0,0.5);
}

.preview {
	padding: 4px 10px;
	position: fixed;
	pointer-events: none;
	z-index: 320;
	.svg-link, .image-search, .mod-checkbox {
		display: none;
	}
}

.shortcut {
	border: none;
	width: 2em;
}

.blinking {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% { opacity: 0.0; }
}

.post-collection {
	white-space: initial;
}

.report-form {
	margin-left: auto;
	margin-right: auto;
}

#captcha-form {
	margin: auto;
    display: block;
    white-space: unset;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
}
