@import 'overlays.mix.less';
@import 'mod.mix.less';
@import 'posts.mix.less';

a {
	border: none;
	text-decoration: none !important;
	&.nope, &.nope:hover {
		color: inherit;
	}
	&.disabled {
		pointer-events: none;
		color: inherit;
	}
}

a, .svg-link {
	cursor: pointer;
}

.svg-link svg {
	fill: currentColor;
}

.watcher-toggle.svg-link svg {
	width: 1em;
	height: 1em;
}

.watcher-toggle.enabled {
	border: solid 1px currentColor;
}

article, aside {
	padding: 4px 10px;
	margin: 1px;
}

body {
	overflow-x: hidden;
	margin: 0;
	padding: 0;
}

#threads {
	flex: 1;
	padding: 1em 1.4em;
	outline: none;
	max-width: 100vw;
}

aside {
	display: table;
	&.disabled a {
		color: grey !important;
		cursor: default;
	}
}

.bold {
	font-weight: bold;
}

.mono {
	font-family: monospace;
}

textarea {
	resize: both;
	font-family: sans-serif;
	width: 100%;
}

iframe {
	background: white;
}

.full-width {
	width: 100%;
}

.close {
	font-weight: bold;
}

.popup-menu, .act, .svg-link {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

h1, h2 {
	text-align: center;
}

h3 {
	display: inline;
	font-size: inherit;
}

.hidden {
	display: none;
}

.spaced {
	& > *:before {
		content: ' ';
	}
	& > .act:not(.expanded):before {
		content: " [";
	}
}

.margin-spaced > * + * {
	margin-left: 0.1em;
}

hr {
	border: none;
	clear: both;
}

.hover-reveal {
	& > span:last-child {
		display: none;
		user-select: initial;
	}
	&:hover > span:last-child {
		display: block;
		position: absolute;
		margin: 0;
		white-space: pre-wrap;
	}
}

.act {
	&:not(.expanded) {
		&:before {
			content: "[";
		}
		&:after {
			content: "]";
		}
	}
	& + & {
		margin-left: 0.1em;
	}
}

.hide-empty:empty {
	display: none;
}

.top-margin {
	margin-top: 1em;
}

#user-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -100;
	video {
		height: auto;
		width: 100%;
	}
}

#mascot-image, #elka-image {
	position: fixed;
    bottom: 0;
    right: 0;
    z-index: -99;
	max-width:50%;
	max-height:50%;
	width: auto;
	height: auto;
}

#elka-image {
    opacity: 0.3;
}

#lock {
	margin-left: auto;
	padding-left: 15px;
}

#bottom:focus {
	outline: 0;
}

table {
	border-collapse:collapse;
	label {
		padding: 0.2em;
	}
}

td {
	padding: 0;
}

.flex {
	display: flex;
	flex-wrap: wrap;
}

.aside-container {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
}

#catalog-controls {
	margin-left: auto;
}

.captcha-container {
	padding: 0.5em;
	&, noscript {
		display: grid;
		grid-template-rows: auto;
		grid-template-columns: 240px;
	}
	img {
    	background: white;
	}
}

.flag {
    height: .8em;
    margin-left: .3em;
    border: 1px solid black;
}

.fileinfo {
	margin-left: 1ex;
	&::before {
		content: "(";
	}
	&::after {
		content: ")";
	}
	& > span::empty {
		display: none;
	}
	& > span:not(:empty):not([hidden]) ~ span::before {
		content: ", ";
	}
	&  > span.media-artist:not(:empty) + span.media-title::before {
		content: "\00a0-\00a0";
	}
}

@media screen and (max-width: 700px) {
	body {
		margin: 1px 0;
	}
	figure {
		padding: 0;
	}
	img.expanded {
		margin: 0;
	}
	blockquote {
		margin: 0.5em 0 0.5em 0.5em;
		min-width: 15em;
	}
	#threads {
		padding: 1em 0.3em;
	}
	#thread-post-counters, #sync-counter, .image-banner {
		display: none;
	}
	#post-controls  {
		width: 100%;
	}
}

#noscript-overlay {
	font-size: 15vw;
	display: flex;
	& > * {
		margin: auto;
	}
}

@media (pointer: coarse) {
	#banner {
		&, a {
			font-size: 120%;
		}
		height: 1.5em;
	}
	.banner-float.svg-link  {
		svg {
			width: 1.2em;
			height: 1.2em;
		}
		font-size: 120%;
	}
}
